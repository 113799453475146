<template>
    <section class="order-form-step projects">
        <header class="order-form-step__heading">
            <h1 class="order-form-step__heading">
                Projects In Cart
            </h1>
        </header>
        <main>
            <div class="projects-in-cart">
                <div
                    v-for="(item, index) in orders"
                    :key="index"
                    class="projects-in-cart__content"
                >
                    <div
                        class="projects-in-cart__content-first"
                    >
                        <div
                            class="projects-in-cart__title"
                            :class="{'projects-in-cart__title--big' : item.discount_rate > 0}"
                        >
                            <span class="projects-in-cart__order">{{ index + 1 }}.</span>
                            <p>
                                <span class="order_title">
                                    «{{ item.title.slice(0,25) }}
                                    <span
                                        v-if="item.title.length > 25"
                                        class="custom-tooltip custom-tooltip--title"
                                    >
                                        <p>...</p>
                                        <span>{{ item.title }}</span>
                                    </span>
                                    »
                                </span>
                            </p>
                        </div>
                        <div>
                            <!-- discount block -->
                            <div
                                v-if="item.discount_rate > 0"
                                class="projects-in-cart__discount"
                            >
                                <div
                                    v-if="item.discount_type === 'code'"
                                    class="projects-in-cart__discount-code"
                                >
                                    {{ `"${item.discount_code}"` }}
                                </div>
                                <div
                                    v-else
                                    class="projects-in-cart__discount-loyalty"
                                >
                                    Loyalty
                                </div>
                                <div>
                                    <span :class="item.discount_type === 'code' ? 'icon-code' : 'icon-loyalty' ">
                                        {{ item.discount_rate | percent }}
                                    </span>
                                </div>
                            </div>
                            <!-- discount block -->
                            <!-- total -->
                            <div
                                v-if="item.quote"
                                class="projects-in-cart__price"
                            >
                                {{ item.quote.total | money }}
                            </div>
                            <span
                                v-else
                                class="order_title-not-valid not-valid-desktop"
                            >
                                Not valid
                            </span>
                            <!-- total -->
                            <!-- action buttons -->
                            <div class="projects-in-cart__modal">
                                <span
                                    v-if="!isExpressOrder(item)"
                                    class="edit"
                                    @click="editOrder(item.orderid)"
                                >
                                    <PencilOutline />
                                    <span class="tool">
                                        Edit
                                    </span>
                                </span>
                                <span
                                    class="delete"
                                    @click="deleteOrder(item.orderid)"
                                >
                                    <DeleteOutline />
                                    <span class="tool">
                                        Delete
                                    </span>
                                </span>
                            </div>
                            <!-- action buttons -->
                        </div>
                    </div>
                    <div class="projects-in-cart__types">
                        <div
                            v-if="!isExpressOrder(item)"
                            class="projects-in-cart__date"
                        >
                            <div class="projects-in-cart__date__title">
                                Project type
                            </div>
                            <div class="projects-in-cart__date__title-text">
                                <FileDocument class="quote-sidebar__icon" />
                                {{ item.type }}
                            </div>
                        </div>
                        <div
                            v-if="!isExpressOrder(item)"
                            class="projects-in-cart__date"
                        >
                            <div class="projects-in-cart__date__title">
                                Academic level
                            </div>
                            <div class="projects-in-cart__date__title-text">
                                <BookOpenVariant class="quote-sidebar__icon" />
                                {{ item.academiclevel }}
                            </div>
                        </div>
                    </div>
                    <div class="projects-in-cart__date-wrapper">
                        <div class="projects-in-cart__date">
                            <div class="projects-in-cart__date__title">
                                Due in {{ item.due_at | moment_from }}
                            </div>
                            <div>
                                <ClockTimeFourOutline class="quote-sidebar__icon" />
                                {{ item.due_at | moment_short_text }}
                            </div>
                        </div>
                        <!-- mobile discount -->
                        <div
                            v-if="item.discount_rate > 0"
                            class="projects-in-cart__discount projects-in-cart__discount-mobile"
                        >
                            <div
                                v-if="item.discount_type === 'code'"
                                class="projects-in-cart__discount-code"
                            >
                                {{ `"${item.discount_code}"` }}
                            </div>
                            <div
                                v-else
                                class="projects-in-cart__discount-loyalty"
                            >
                                Loyalty
                            </div>
                            <div>
                                <span :class="item.discount_type === 'code' ? 'icon-code' : 'icon-loyalty' ">
                                    {{ item.discount_rate | percent }}
                                </span>
                            </div>
                        </div>
                        <!-- mobile discount -->
                        <!-- mobile total -->
                        <div
                            v-if="item.quote"
                            class="projects-in-cart__price-mobile"
                        >
                            {{ item.quote.total | money }}
                        </div>
                        <span
                            v-else
                            class="order_title-not-valid not-valid-mobile"
                        >
                            Not valid
                        </span>
                        <!-- mobile total -->
                    </div>
                    <!-- extras -->
                    <div class="projects-in-cart__details">
                        <div
                            v-if="item.pages"
                            class="projects-in-cart__pages projects-in-cart__item"
                        >
                            <p>
                                <span v-if="item.pages > 1">Pages</span>
                                <span v-else>Page</span>
                            </p>
                            <div>
                                <FileOutlineIcon class="quote-sidebar__icon" />
                                {{ item.pages }}
                            </div>
                        </div>
                        <div
                            v-if="item.slides"
                            class="projects-in-cart__slides projects-in-cart__item"
                        >
                            <p>
                                <span v-if="item.slides > 1">Slides</span>
                                <span v-else>Slide</span>
                            </p>
                            <div>
                                <ImageOutlineIcon class="quote-sidebar__icon" />
                                {{ item.slides }}
                            </div>
                        </div>
                        <div
                            v-if="item.charts"
                            class="projects-in-cart__charts projects-in-cart__item"
                        >
                            <p>
                                <span v-if="item.charts > 1">Charts</span>
                                <span v-else>Chart</span>
                            </p>
                            <div>
                                <ChartBoxOutlineIcon class="quote-sidebar__icon" />
                                {{ item.charts }}
                            </div>
                        </div>
                        <div
                            v-if="item.problems"
                            class="projects-in-cart__problems projects-in-cart__item"
                        >
                            <p>
                                <span v-if="item.problems > 1">Problems</span>
                                <span v-else>Problem</span>
                            </p>
                            <div>
                                <AlertOutlineIcon class="quote-sidebar__icon" />
                                {{ item.problems }}
                            </div>
                        </div>
                        <div
                            v-if="item.quote && item.quote.preferred_writers && item.quote.preferred_writers.count"
                            class="projects-in-cart__preferred_writers projects-in-cart__item"
                        >
                            <p>Preferred</p>
                            <div>
                                <Writers class="quote-sidebar__icon" />
                                {{ item.quote.preferred_writers.count }}
                            </div>
                        </div>
                        <div
                            v-if="item.linked_to_order"
                            class="projects-in-cart__linked_order projects-in-cart__item"
                        >
                            <div>
                                {{ item.linked_to_order }}
                            </div>
                        </div>
                        <div
                            v-if="item.files_count"
                            class="projects-in-cart__files projects-in-cart__item"
                        >
                            <p>
                                <span v-if="item.files_count > 1">Files</span>
                                <span v-else>File</span>
                            </p>
                            <div>
                                <PaperClip class="quote-sidebar__icon" />
                                {{ item.files_count }}
                            </div>
                        </div>
                        <div
                            v-if="item.quote && item.quote.chk_abstract && item.quote.chk_abstract.required"
                            class="projects-in-cart__abstract projects-in-cart__item"
                        >
                            <p><span>Abstract</span></p>
                            <div><Check class="quote-sidebar__icon-check" /></div>
                        </div>
                        <div
                            v-if="item.quote && item.quote.chk_outline && item.quote.chk_outline.required"
                            class="projects-in-cart__outline projects-in-cart__item"
                        >
                            <p><span>Outline</span></p>
                            <div><Check class="quote-sidebar__icon-check" /></div>
                        </div>
                    </div>
                    <!-- extras -->
                </div>
            </div>
        </main>
        <footer>
            <div class="projects-in-cart-footer">
                <button
                    class="btn-base btn-main projects-in-cart__button"
                    @click="createNewOrder"
                >
                    Add another order
                    <span class="custom-tooltip">
                        <p>?</p>
                        <span>If you have more than one project, checkout faster by entering all your projects in your cart and then paying only once.</span>
                    </span>
                </button>
            </div>
        </footer>
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
// icons
import FileOutlineIcon from 'mdi-vue/FileOutline.vue'
import ImageOutlineIcon from 'mdi-vue/ImageOutline.vue'
import ChartBoxOutlineIcon from 'mdi-vue/ChartBoxOutline.vue'
import AlertOutlineIcon from 'mdi-vue/AlertOutline.vue'
import DeleteOutline from 'mdi-vue/DeleteOutline.vue'
import PencilOutline from 'mdi-vue/PencilOutline.vue'
import ClockTimeFourOutline from 'mdi-vue/ClockTimeFourOutline.vue'

import PaperClip from '@/components/icons/PaperClip'
import Check from '@/components/icons/Check'

// vuex bindings
import FileDocument from 'mdi-vue/FileDocument.vue';
import BookOpenVariant from 'mdi-vue/BookOpenVariant.vue';
import filtersMixin from '@/mixins/filters-mixin.js'
import Writers from '@/components/icons/Writers'
// import WritersBlocked from '../icons/Writers-blocked'
// vuex bindings
import {
    CLEAR_FORM_STANDARD
} from '@/store/modules/order/mutation-types'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')
export default {
    name: 'ProjectsInCart',
    components: {
        // WritersBlocked,
        Writers,
        DeleteOutline,
        PencilOutline,
        FileOutlineIcon,
        ImageOutlineIcon,
        ChartBoxOutlineIcon,
        ClockTimeFourOutline,
        AlertOutlineIcon,
        FileDocument,
        BookOpenVariant,
        PaperClip,
        Check
    },
    mixins: [
        filtersMixin
    ],
    props: {
        orders: {
            type: Array,
            required: true
        }
    },
    computed: {
        calculatorData() {
            return this.$store.state.config.pricing
        }
    },
    methods: {
        ...mapOrderMutations([
            CLEAR_FORM_STANDARD
        ]),
        isExpressOrder(order) {
            return order.academiclevel === null && order.type === null
        },
        createNewOrder() {
            this[CLEAR_FORM_STANDARD]()
            this.$router.push({ name: 'order' })
        },
        deleteOrder(index) {
            this.$emit('eventDeleteOrder', index)
        },
        addNewOrder() {
            this.$emit('eventAddNewOrder')
        },
        editOrder(orderid) {
            this.$emit('eventEditOrder', orderid)
        }
    }
}
</script>

<style lang="scss">
.projects {
    margin-top: 10px;
}

.projects-in-cart {
    &__item {
        width: 60px;
    }
    &__pages, &__charts, &__files,
    &__problems, &__slides,
    &__date, &__price,
    &__linked_order,
    &__blocked_writers, &__preferred_writers, &__abstract, &__outline,
    &__price-mobile, &__order {
        p, &::before {
            color: #828282;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
        }

        div {
            margin-top: 10px;
        }
    }

    &__types{
        width: 100%;
        display: flex;
        @media all and (max-width: 560px) {
            flex-direction: column;
            .projects-in-cart__date{
                &:last-child{
                    margin-top: 20px;
                }
            }
        }
    }

    &__blocked_writers, &__preferred_writers {
        img {
            min-width: 24px;
            min-height: 24px;
        }
    }

    &__blocked_writers {
        &::before {
            content: 'Blocked';
        }
    }

    &__linked_order {
        div {
            margin-top: 14px;
        }
        &::before {
            content: 'Source order: ';
        }
    }
    .quote-sidebar__icon {
        margin-right: 10px;
    }
    .quote-sidebar__icon-check {
        width: 20px;
        height: 20px;
    }

    &__content{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #D3D9E2;
        padding-bottom: 25px;
        padding-top: 45px;

        &--error{
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: -20px;
                right: -20px;
                top: 0;
                bottom: 0;
                background: rgba(255,0,0,0.1);
            }
        }

        &:last-child {
            margin-bottom: 0;
            border: none;
        }

        &:first-child {
            margin-top: 15px;
            padding-top: 0;

            .projects-in-cart__price::before {
                content: 'Amount';
            }
        }

        &-first {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            div:nth-child(2) {
                display: flex;
                align-items: flex-end;
            }
        }
    }

    &__discount {
        width: 128px;
        height: 78px;
        background: rgba(#219653, 0.1);
        border-radius: 4px;
        color: #219653;
        font-weight: bold;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 15px 9px;
        margin-bottom: -15px;
        margin-right: 20px;

        &-loyalty, &-code  {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }

        &-mobile {
            display: none;
        }

        .icon {
            &-code, &-loyalty {
                display: flex;
                align-items: flex-end;
                &::before {
                    content: '';
                    display: block;
                    min-width: 25px;
                    min-height: 25px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 10px;
                    margin-bottom: -3px;
                }
            }
            &-code {
                &:before {
                    background-image: url('~@/assets/img/svg/discount-code.svg');
                }
            }

            &-loyalty {
                &:before {
                    background-image: url('~@/assets/img/svg/discount-loyalty.svg');
                }
            }
        }
    }

    &__title {
        display: flex;
        width: calc(100% - 165px);
        margin-right: 10px;
        font-weight: 700;
        position: relative;
        &--big{
            width: calc(100% - 311px);
        }
        @media all and (max-width: 480px) {
            width: calc(100% - 75px);
        }
        p {
            display: flex;
            .order_title {
                margin-left: 10px;
                display: flex;
                & > span {
                    &.custom-tooltip{
                        position: relative;
                        bottom: -12px;
                        left: 0;
                        cursor: pointer;
                        margin: 0px;
                    }
                }
            }
        }
        span {
            margin-bottom: 0;
        }
    }
    &__details {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        div {
            margin-right: 20px;
        }
    }

    &__date {
        margin-top: 20px;
        margin-right: 25px;

        &__title {
            color: #828282;
            margin-top: 0 !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }

        &__title-text {
            width: 225px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block !important;
            @media all and (max-width: 460px) {
                width: 100%;
                overflow: inherit;
                display: flex;
            }
        }
    }

    &__slides, &__pages, &__date, &__charts, &__problems, &__blocked_writers, &__preferred_writers, &__files {
        margin-top: 20px;
        div {
            display: flex;
            align-items: center;
        }

        img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
    }

    &__modal{
        flex-basis: 8%;
        display: flex;
        justify-content: space-between;

        span {
            cursor: pointer;
            transition: .2s;

            .mdi-pencil-outline {
                margin-right: 5px;
                &:hover {
                    color: $main-color;
                }
            }

            .mdi-delete-outline:hover {
                color: #ff083c;
            }
        }
    }

    &__price {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        font-size: 18px;
        font-weight: 700;
        min-width: 80px;

        &-mobile {
            display: none;
            font-weight: 700;
        }
    }

    &__pages {
        margin-top: 20px;
        div {
            display: flex;
            align-items: center;
        }

        img {
            margin-right: 10px;
            width: 16px;
            height: 20px;
        }
    }

    &__order {
        border-radius: 4px;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        overflow: visible !important;

        &::before {
            position: absolute;
            top: -30px;
            content: 'Title';
            margin-bottom: 7px;
            @media all and (max-width: 480px) {
                display: none;
            }
        }
    }

    &__button {
        position: relative;
        padding: 8px 20px;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .projects-in-cart {
        &__discount {
            display: none;

            &-mobile {
                display: flex;
                padding: 10px 9px 13px;
                margin-bottom: -13px;
                margin-right: 15px;

                .icon-loyalty {
                    font-size: 16px;
                }
            }
        }
        &__content {
            flex-wrap: wrap;
            position: relative;

            &:last-child {
                margin-bottom: 0px;
            }

            div.projects-in-cart__price {
                display: none;
            }

            &-first {
                margin-bottom: 20px;
                align-items: flex-start;
            }
        }

        &__blocked_writers, &__preferred_writers {
            margin-top: 20px;
        }

        &__date, &__pages {
            //display: flex;
            flex-direction: column;
            &::before {
                font-size: 14px;
                color: #828282;
                margin-bottom: 0px;
            }
        }

        &__details div  {
            margin-right: 17px;
        }

        &__order {
            min-width: 30px;
        }

        &__price {
            display: none;
            &-mobile {
                display: flex;
                flex-direction: column;
                height: 54px;
                &::before {
                    content: 'Price';
                    margin-bottom: 13px;
                }
            }
        }
        &__date {
            margin-top: 0px;
            margin-right: 15px;

            div {
                align-items: flex-end;
            }

            &-wrapper {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
                .projects-in-cart__date {
                    div {
                        align-items: center;
                    }
                }
                .projects-in-cart__price-mobile{
                    margin-top: 20px;
                }
                .projects-in-cart__discount-mobile {
                    margin-top: 20px;
                }
            }
        }

        &__title  {
            & > p {
                display: flex;
                flex-direction: column;
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 375px) {
    .projects-in-cart__linked_order {
        margin-top: 20px;
    }
    .projects-in-cart__date {
        margin-right: 9px;
        margin-top: 0;
    }
}

@media (max-width: 320px) {
    .projects-in-cart__linked_order {
        margin-top: 13px;
    }
    .projects-in-cart__details div {
        margin-right: 13px;
    }
}
.custom-tooltip--title {
    height: 16px;
    width: 16px;
    font-size: 10px;
    top: 50%;
    right: -5px;
    border: none;
}

.order_title-not-valid {
    background: red;
    white-space: nowrap;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    margin-right: 25px;
}

.not-valid-desktop {
    @media (max-width: 480px) {
        display: none;
    }
}

.not-valid-mobile {
    display: none;
    @media (max-width: 480px) {
        display: flex;
    }
}

</style>
