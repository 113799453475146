<template>
    <div style="display: flex">
        <div class="payment-details__wrapper">
            <div class="payment-details__info">
                <span>
                    <!-- Our Worldpay gateway accepts only MasterCard and Visa cards. For all other cards, please use our Paypal alternative gateway. -->
                    <span class="pay-image">
                        <img
                            src="../../assets/img/svg/visa.svg"
                            alt="visa"
                        >
                        <img
                            src="../../assets/img/svg/mastercard.svg"
                            alt="mastercard"
                        >
                    </span>
                </span>
            </div>
            <div
                class="payment-details__cards"
                :style="{'background-color': bg_color}"
                :class="{'visa' : cc_type === `visa`}"
            >
                <div class="payment-details__row">
                    <div class="payment-details__column payment-details__column--left">
                        <form-masked-input
                            v-model="$v.cc_number.$model"
                            legend="Card"
                            :field.sync="cc_number"
                            placeholder="XXXX XXXX XXXX XXXX"
                            mask="#### #### #### ####"
                            name="cc_number"
                            :errors="validationMessage($v.cc_number)"
                            :is-valid="$v.cc_number.$dirty && !$v.cc_number.$anyError"
                            :bg_color="bg_color"
                            @input.native="$v.cc_number.$touch()"
                            @blur.native="$v.cc_number.$touch()"
                        />
                    </div>
                    <div class="payment-details__column payment-details__column--right payment-details__column-img">
                        <img
                            v-show="cc_type === `visa`"
                            src="../../assets/img/svg/visa_white_logo.svg"
                            alt="Visa"
                            class="visa"
                        >
                        <img
                            v-show="cc_type === `mastercard`"
                            src="../../assets/img/svg/mastercard_checkout.svg"
                            alt="Mastercard"
                            class="mastercard"
                        >
                    </div>
                </div>
                <div class="payment-details__row">
                    <div class="payment-details__column payment-details__column--left">
                        <form-input
                            v-model="$v.cc_name.$model"
                            legend="Cardholder Full Name"
                            :field.sync="cc_name"
                            name="cc_name"
                            placeholder="YOUR NAME"
                            :errors="validationMessage($v.cc_name)"
                            :is-valid="$v.cc_name.$dirty && !$v.cc_name.$anyError"
                            :bg_color="bg_color"
                            @input.native="$v.cc_name.$touch()"
                            @blur.native="$v.cc_name.$touch()"
                        />
                    </div>
                    <div class="payment-details__column payment-details__column--right date-cvv">
                        <form-masked-input
                            v-model="$v.cc_expiry.$model"
                            legend="MO/YR"
                            :field.sync="cc_expiry"
                            placeholder="mm/yyyy"
                            mask="## / ##"
                            :bg_color="bg_color"
                            name="cc_expiry"
                            :errors="validationMessage($v.cc_expiry)"
                            :is-valid="$v.cc_expiry.$dirty && !$v.cc_expiry.$anyError"
                            @input.native="$v.cc_expiry.$touch()"
                            @blur.native="$v.cc_expiry.$touch()"
                        />
                        <form-masked-input
                            v-model="$v.cc_cvv.$model"
                            legend="CVV/CVC"
                            :field.sync="cc_cvv"
                            placeholder="* * *"
                            mask="###"
                            :bg_color="bg_color"
                            name="cc_cvv"
                            :errors="validationMessage($v.cc_cvv)"
                            :is-valid="$v.cc_cvv.$dirty && !$v.cc_cvv.$anyError"
                            @input.native="$v.cc_cvv.$touch()"
                            @blur.native="$v.cc_cvv.$touch()"
                        />
                    </div>
                </div>
                <div class="payment-details__row">
                    <div class="payment-details__column payment-details__column--left">
                        <form-input
                            v-model="$v.cc_address.$model"
                            legend="Billing address"
                            :field.sync="cc_address"
                            name="cc_address"
                            placeholder="Address"
                            :errors="validationMessage($v.cc_address)"
                            :is-valid="$v.cc_address.$dirty && !$v.cc_address.$anyError"
                            :bg_color="bg_color"
                            @input.native="$v.cc_address.$touch()"
                            @blur.native="$v.cc_address.$touch()"
                        />
                    </div>
                    <div class="payment-details__column payment-details__column--right">
                        <form-input
                            v-model="$v.cc_zip.$model"
                            legend="Billing ZIP code"
                            :field.sync="cc_zip"
                            name="cc_zip"
                            placeholder="ZIP code"
                            :errors="validationMessage($v.cc_zip)"
                            :is-valid="$v.cc_zip.$dirty && !$v.cc_zip.$anyError"
                            :bg_color="bg_color"
                            @input.native="$v.cc_zip.$touch()"
                            @blur.native="$v.cc_zip.$touch()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// components

import creditCardType from 'credit-card-type';
// validation
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/form-validation-mixin.js'
import { validationRules } from '@/validation/checkout/rules-credit-card.js'
import { formMessages } from '@/validation/checkout/messages-credit-card.js'

// vuex bindings
import { mapCreditCardFields } from '@/store/modules/checkout/'

export default {
    name: 'PaymentDetailsCreditCard',

    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    computed: {
        ...mapCreditCardFields([
            'form_data_credit_card.cc_type',
            'form_data_credit_card.cc_name',
            'form_data_credit_card.cc_number',
            'form_data_credit_card.cc_cvv',
            'form_data_credit_card.cc_expiry',
            'form_data_credit_card.cc_address',
            'form_data_credit_card.cc_zip'
        ]),
        isFormValid() {
            return !this.$v.$invalid
        },
        bg_color() {
            return this.cc_type === '' || this.cc_type === 'visa' ? '#233C67' : '#0D2629'
        }
    },
    watch: {
        cc_number() {
            const object = creditCardType(this.cc_number);
            if (object.length === 12) {
                this.cc_type = ''
            } else if (object.length === 1) {
                this.cc_type = object[0].type
            } else if (object.length === 0) {
                this.cc_type = ''
            }
        },
        isFormValid(value) {
            this.$emit('validated', value)
        }
    },
    created() {
        if (this.cc_number || this.cc_expiry) {
            this.$v.$touch()
        }
    },
    mounted() {
        this.$emit('validated', this.isFormValid)
    },
    methods: {
        validationMessage: validationMessage(formMessages)
    }
}
</script>

<style lang="scss">

.payment-details__wrapper{
    width: 700px;
    @media (max-width: 800px) {
        width: 100%;
    }
}

.payment {
    padding: 0;
    .order-form-step__heading h1{
        padding: 20px;
    }
    .row.btn {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 20px;
    }
}
#payment-details {
    padding: 0;
}
.payment-details {
    &__tabs {
        display: flex;
    }
    &__tabs-item {
        width: 50%;
        padding: 17px 0;
        // font-family: Mark Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        border-bottom: 1px solid #D3D9E2;
        cursor: pointer;
        &--selected {
            border-bottom: 3px solid $main-color;
        }
    }
    &__info {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #757D89;
        margin-top: 17px;
        .paypal {
            .pay-image {
                margin-bottom: 20px;
            }
        }
        span {
            display: flex;
            flex-direction: column;
            .pay-image {
                align-items: baseline;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 10px;
                img {
                    margin-right: 30px;
                    height: 33px;
                }
            }
        }
    }
    &__cards {
        background-color: #233C67;
        border-radius: 16px;
        padding: 30px 40px 15px;
        display: flex;
        flex-direction: column;
        margin: 20px;
        position: relative;
        overflow: hidden;
        &::before {
            content: '';
            height: 12px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: $main-color;
        }
        &.visa {
            &::before {
                background:#faa61a;
            }
        }
        input {
            border: 1px solid rgba(#fff, 0.7);
            color: #fff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        legend {
            color: #fff;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        padding: 25px 0;

        .payment-details__column-img .visa{
            width: 100%;
            height: 34px;
        }
    }
    &__column {
        display: flex;
        &--left {
            width: 65%;
        }
        &--right {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            .mastercard {
                width: 100%;
                height: auto;
            }
        }
        &.date-cvv {
            justify-content: space-between;
            .form-masked-input__fieldset {
                width: 45%;
            }
        }
    }
}
@media (max-width: 576px) {
    .payment-details {
        &__cards {
            padding: 30px 20px 20px;
            .payment-details__row {
                flex-direction: column;
                .mastercard {
                    width: 55%;
                    margin: 20px 0;
                }
                .visa {
                    width: 40%;
                    margin: 20px 0;
                }
                &:first-child {
                    padding: 0 0 10px;
                    .payment-details__column:first-child {
                        order: 2;
                        margin-top: 10px;
                    }
                }
                &:nth-child(2) {
                    padding: 20px 0 10px;
                    .payment-details__column:first-child {
                        margin-bottom: 30px;
                    }
                }
                &:last-child {
                    padding: 20px 0 0;
                    .payment-details__column:first-child {
                        margin-bottom: 30px;
                    }
                }
                .payment-details__column {
                    width: 100%;

                    &-img {
                        display: none;
                    }
                }
            }
        }
        &__info {
            span.pay-image {
                align-items: center;

                img:last-child {
                    margin-top: 20px;
                }
            }
        }
    }
}
@media (max-width: 320px) {
    .breadcrumbs--item a {
        font-size: 14px;
    }
    .payment-details {
        &__info {
            span .pay-image {
                justify-content: flex-start;
                img:first-child {
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
                img:nth-child(3) {
                    margin-top: 20px;
                }
            }
        }
    }
}
</style>
